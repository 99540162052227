import { FieldGeneral } from 'api/endpoints/processFields';

import { ERROR_KEYS } from 'constants/fieldErrors';
import { DataModelFieldSubType } from 'constants/global';

import { normalizeDate, normalizePhoneField } from 'utils/normalizers/string';

type DefaultValueType = number | string;

export type ValidityChecker<
  V = DefaultValueType,
  AV = { [key: string]: DefaultValueType },
> = (value: V, allValues?: AV) => boolean;

export type ValidationErrorMessage =
  | string
  | undefined
  | {
      key: string;
      options?: {
        [key: string]: unknown;
      };
    };

export type Validator<
  V = DefaultValueType,
  AV = { [key: string]: DefaultValueType },
> = (value: V, allValues?: AV) => ValidationErrorMessage;

export function createValidator<
  V = DefaultValueType,
  AV = { [key: string]: DefaultValueType },
>(
  validator: ValidityChecker<V, AV> | Validator<V, AV>,
  message: ValidationErrorMessage = 'Validation error'
): Validator<V, AV> {
  return (value: V, allValues?: AV) => {
    const invalid = validator(value, allValues);

    return invalid ? message : undefined;
  };
}

export const isRequired = createValidator((value) => !value && value !== 0, {
  key: ERROR_KEYS.IS_REQUIRED,
});

export const isValidName = createValidator(
  (value) =>
    Boolean(value && !new RegExp(FIRST_LAST_NAME_REGEX).test(String(value))),
  {
    key: ERROR_KEYS.INVALID_NAME,
  }
);

// TODO: MOVE TO REGEX CONSTANT FILE

// sourced from https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/email#basic_validation
export const EMAIL_REGEX =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

const USERNAME_REGEX = /^[a-zA-Z0-9.,-_:*&^%$#@!+=]+$/;

export const UUID_REGEX = /^[0-9a-f]{8}-([0-9a-f]{4}-){3}[0-9a-f]{12}$/i;

const PASSWORD_REGEX = /^[a-zA-Z0-9]+$/;

const ALIAS_REGEX = /^[a-zA-Z0-9\s]+$/;

const EMAIL_TEMPLATE_INDEX_REGEX = /^[a-zA-Z0-9]+$/;

export const URL_REGEX =
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;

export const WWW_URL_REGEX = /(((^www\.))[\S]+)/i;

// Covers most international cases of names, it does not cover emojis, however
export const FIRST_LAST_NAME_REGEX =
  /^([a-zA-Z\xC0-\uFFFF]+([ \-']{0,1}[a-zA-Z\xC0-\uFFFF]+)*[.]{0,1}){1,2}$/;

// TODO: SPLIT FOR DIFFERENT FILES

export const isUrlAddress = (key = ERROR_KEYS.INCORRECT_URL_ADDRESS) =>
  createValidator(
    (value) => Boolean(value && !new RegExp(URL_REGEX).test(String(value))),
    {
      key,
    }
  );

export const email = (key = ERROR_KEYS.INCORRECT_EMAIL) =>
  createValidator(
    (value) => Boolean(value && !new RegExp(EMAIL_REGEX).test(String(value))),
    {
      key,
    }
  );

export const uuid = (key = ERROR_KEYS.INCORRECT_GUID) =>
  createValidator(
    (value) => Boolean(value && !new RegExp(UUID_REGEX).test(String(value))),
    {
      key,
    }
  );

export const severalEmails = (key = ERROR_KEYS.INCORRECT_EMAIL) =>
  createValidator(
    (value) => {
      if (!value) return false;
      const emailsObjects = value as unknown as OptionItem[];

      const notEmpty = emailsObjects.filter((e) => !!e.name);

      const emails = notEmpty.map((e) => e.name);

      return !emails.every((e) => new RegExp(EMAIL_REGEX).test(String(e)));
    },
    {
      key,
    }
  );

export const severalUniqueEmails = (
  key = ERROR_KEYS.NOT_UNIQUE_EMAIL_IN_ARRAY
) =>
  createValidator(
    (value) => {
      if (!value) return false;
      const emailsObjects = value as unknown as OptionItem[];

      const notEmpty = emailsObjects.filter((e) => !!e.name);

      const emails = notEmpty.map((e) => e.name);

      const uniqueEmails = [...new Set(emails)];

      return uniqueEmails.length !== emails.length;
    },
    {
      key,
    }
  );

export const userName = createValidator(
  (value) =>
    Boolean(value && !new RegExp(USERNAME_REGEX, 'g').test(String(value))),
  {
    key: ERROR_KEYS.INCORRECT_USER_NAME,
  }
);

export const password = createValidator(
  (value) => Boolean(value && !new RegExp(PASSWORD_REGEX).test(String(value))),
  {
    key: ERROR_KEYS.INCORRECT_USER_PASSWORD,
  }
);

export const externalPassword = createValidator(
  (value) => Boolean(value && !new RegExp(USERNAME_REGEX).test(String(value))),
  {
    key: ERROR_KEYS.INCORRECT_EXTERNAL_PASSWORD,
  }
);

export const passwordLength = createValidator(
  (value: string | undefined) =>
    Boolean(value && (value.length < 8 || value.length > 64)),
  {
    key: ERROR_KEYS.INCORRECT_USER_PASSWORD_LENGTH,
  }
);

export const emailTemplateIndex = createValidator(
  (value: string | undefined) =>
    Boolean(value && !EMAIL_TEMPLATE_INDEX_REGEX.test(value)),
  {
    key: ERROR_KEYS.WRONG_EMAIL_TEMPLATE_INDEX,
  }
);

export const maxLength = (length: number, type = ERROR_KEYS.INCORRECT_LENGTH) =>
  createValidator(
    (value) =>
      Boolean(value && typeof value === 'string' && value.length >= length),
    {
      key: type,
      options: {
        length: length - 1,
      },
    }
  );

export const rangeLength = (min: number, max: number) =>
  createValidator(
    (value) => {
      const normalized = typeof value === 'number' ? String(value) : value;
      return Boolean(
        normalized && (normalized.length < min || normalized.length >= max)
      );
    },
    {
      key: ERROR_KEYS.INCORRECT_RANGE_VALUE_LENGTH,
      options: {
        minLength: min,
        maxLength: max - 1,
      },
    }
  );

export const stableLength = (length: number) =>
  createValidator(
    (value) =>
      Boolean(value && typeof value === 'string' && value.length !== length),
    {
      key: ERROR_KEYS.INCORRECT_STABLE_LENGTH,
      options: {
        length,
      },
    }
  );

export const isAlphaNumericValue = (
  type = ERROR_KEYS.INCORRECT_PASSWORD_VALUE
) =>
  createValidator<string>(
    (value) => (!value ? false : !value.match(PASSWORD_REGEX)),
    {
      key: type,
    }
  );

export const isPhoneFormat = createValidator(
  (value) => {
    if (!value) return false;

    const formattedValue = normalizePhoneField(String(value));

    return formattedValue?.length < 10;
  },
  {
    key: ERROR_KEYS.WRONG_FORMAT_PHONE,
  }
);

const isLessOrGrater = (value: string | number, min: number, max: number) =>
  Number(value) < min || Number(value) > max;

const validateDateParts = (month: string, day: string, year: string) =>
  isLessOrGrater(day, 1, 31) ||
  isLessOrGrater(month, 1, 12) ||
  isLessOrGrater(year, 1900, 2100);

export const isDateFormat = (type = DataModelFieldSubType.DateDefault) =>
  createValidator(
    (value) => {
      if (!value) return false;

      const normalizedValue = normalizeDate(String(value));

      const reverseDates = [DataModelFieldSubType.DateReverse];

      const dateRegExp = /([0-9]{2})([0-9]{2})([0-9]{4})/;

      const [month = '', day = '', year = ''] = normalizedValue
        ?.split(dateRegExp)
        .filter(Boolean);

      if (reverseDates.includes(type))
        return validateDateParts(day, month, year);

      return validateDateParts(month, day, year);
    },
    {
      key: ERROR_KEYS.WRONG_FORMAT_DATE,
    }
  );

export const isAlphaNumericWithSpaceValue = (type: string) =>
  createValidator<string>(
    (value) => (!value ? false : !value.match(ALIAS_REGEX)),
    {
      key: type,
    }
  );

export const isTrue = createValidator((value) => !value, {
  key: 'INCORRECT_TERMS',
});

export const isEmptyOption = createValidator(
  (value: OptionItem[]) =>
    value.filter((optionItem) => optionItem.name.length).length < 2,
  {
    key: ERROR_KEYS.INCORRECT_OPTION_ITEM_LENGTH,
  }
);

export const isUniqueValue = createValidator(
  (optionalItems: OptionItem[]) => {
    const filtered = optionalItems.filter((item) => item.name);

    return (
      [...new Set(filtered.map(({ name }) => name))].length !== filtered.length
    );
  },
  {
    key: ERROR_KEYS.OPTIONS_NAME_IS_NOT_UNIQUE,
  }
);

export const isMatchWithDeleteActionWord = (actionWord: string) =>
  createValidator((value) => value !== actionWord);

export const isRepeatValue = (fieldsToCheck: string[]) =>
  createValidator<DefaultValueType, KeyValuePairs<SelectItem<FieldGeneral>>>(
    (_, allValues) => {
      const initial: { values: string[]; hasValue: string[] } = {
        values: [],
        hasValue: [],
      };
      const res = Object.entries(allValues || {}).reduce(
        (acc, [key, field]) => ({
          values: [
            ...new Set(
              [
                ...acc.values,
                ...(fieldsToCheck.includes(key) && field?.value
                  ? [field?.value]
                  : []),
              ].filter(Boolean)
            ),
          ],
          hasValue: [...acc.hasValue].concat(
            fieldsToCheck.includes(key) && field?.value ? [key] : []
          ),
        }),
        initial
      );

      return Boolean(
        res.values.length && res.values.length !== res.hasValue.length
      );
    },
    {
      key: ERROR_KEYS.FIELD_IS_NOT_UNIQUE,
    }
  );

export const numberInRange = (min: number, max: number) =>
  createValidator((value: string | number) => isLessOrGrater(value, min, max), {
    key: ERROR_KEYS.NUMBER_NOT_IN_RANGE,
    options: {
      min,
      max,
    },
  });

export const validateSearch = (value: string) => value.replace(/["\\]/g, '');
