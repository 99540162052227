import { FieldGeneral } from 'api/endpoints/processFields';
import { WithReleaseOrDraftProcessId, WithFormId } from 'api/types/pathParams';

import ApiUrlParams from 'constants/apiUrlParams';
import { ApiObligationType } from 'constants/form';

import { API_REQ_ACTION_TYPES } from '../actions';
import apiReqSagaCreator from '../apiReqSagaCreator';
import { ApiPaths, HTTP_METHODS } from '../constants';

export const generateMutationPayload = (
  form: FormGeneral
): FormMutationPayload => ({
  description: form.description ?? '',
  fields:
    form.fields?.map((field) => ({
      fieldId: field.id,
      fieldObligationType: field.obligationType,
      multiValues: field.multiValues,
    })) ?? [],
  name: form.name,
  instanceNameFieldId: form.instanceNameField?.id,
  isInitial: form.isInitial,
  isFinal: form.isFinal,
});

export interface FormMutationPayload extends WithHypermedia {
  name: string;
  description: string;
  fields: {
    fieldId: string;
    fieldObligationType: string;
    multiValues?: boolean;
  }[];
  instanceNameFieldId?: string;
  isInitial: boolean;
  isFinal: boolean;
}

export interface FormData {
  data: FormMutationPayload;
}

export interface GetAllForms {
  isInitial?: boolean;
}

export interface FormFieldOpts {
  obligationType: ApiObligationType;
  multiValues?: boolean;
}

export type FieldWithObligationType = FieldGeneral & FormFieldOpts;

export type FormGeneral = BaseEntity &
  WithHypermedia & {
    description?: string;
    fieldsCount?: number;
    fields?: FieldWithObligationType[];
    isInitial: boolean;
    isFinal: boolean;
    instanceNameField?: FieldGeneral;
  };

export type DeleteByIdParams = WithReleaseOrDraftProcessId & WithFormId;
export type DeleteByIdResult = unknown;

export interface UpdateMeta {
  isUpdateInDetails?: boolean;
  stay?: boolean;
}

export type UpdateByIdParams = WithReleaseOrDraftProcessId &
  WithFormId &
  FormData &
  UpdateMeta;
export type UpdateByIdResult = FormGeneral;
export type CreateParams = FormData & WithReleaseOrDraftProcessId;
export type CreateResult = FormGeneral;
export type GetAllFormsParams = Pagination &
  WithReleaseOrDraftProcessId &
  GetAllForms;
export type GetAllFormsResult = DataWithTotal<FormGeneral>;
export type GetFormByIdParams = WithReleaseOrDraftProcessId & WithFormId;
export type GetFormByIdResult = FormGeneral;

const ProcessForm = {
  deleteById: apiReqSagaCreator<DeleteByIdParams, DeleteByIdResult>(
    API_REQ_ACTION_TYPES.FORM.DELETE_FORM_BY_ID,
    (params) => ({
      method: HTTP_METHODS.DELETE,
      url: ApiPaths.processes.byId.processForms.byId._(params),
    })
  ),
  updateById: apiReqSagaCreator<UpdateByIdParams, UpdateByIdResult>(
    API_REQ_ACTION_TYPES.FORM.UPDATE_FORM_BY_ID,
    (args) => ({
      method: HTTP_METHODS.PUT,
      url: ApiPaths.processes.byId.processForms.byId._({
        [ApiUrlParams.releaseOrDraftProcessId]:
          args[ApiUrlParams.releaseOrDraftProcessId],
        [ApiUrlParams.formId]: args[ApiUrlParams.formId],
      }),
      data: args?.data,
    })
  ),
  create: apiReqSagaCreator<CreateParams, CreateResult>(
    API_REQ_ACTION_TYPES.PROCESSES.CREATE_FORM,
    (args) => ({
      method: HTTP_METHODS.POST,
      url: ApiPaths.processes.byId.processForms._({
        [ApiUrlParams.releaseOrDraftProcessId]:
          args[ApiUrlParams.releaseOrDraftProcessId],
      }),
      data: args.data,
    })
  ),
  getAllForms: apiReqSagaCreator<GetAllFormsParams, GetAllFormsResult>(
    API_REQ_ACTION_TYPES.PROCESSES.GET_ALL_FORMS,
    (params) => ({
      method: HTTP_METHODS.GET,
      url: ApiPaths.processes.byId.processForms._({
        [ApiUrlParams.releaseOrDraftProcessId]:
          params[ApiUrlParams.releaseOrDraftProcessId],
      }),
      params: {
        limit: params.limit,
        offset: params.offset,
        isInitial: params.isInitial,
      },
    }),
    {
      withPrevPageOnLessTotal: true,
    }
  ),
  getFormById: apiReqSagaCreator<GetFormByIdParams, GetFormByIdResult>(
    API_REQ_ACTION_TYPES.PROCESSES.GET_FORM_BY_ID,
    (params) => ({
      method: HTTP_METHODS.GET,
      url: ApiPaths.processes.byId.processForms.byId._(params),
    })
  ),
};

export default ProcessForm;
