import asLazyPage from 'hocs/asLazyPage';

import { IS_PROD } from 'utils/localFlags';

export const devRoutes: RoutesConfig = !IS_PROD
  ? [
      {
        path: '/dev/form',
        component: asLazyPage(
          () => import('../../components/molecules/reactHookForm/page')
        ),
        exact: true,
      },
      {
        path: '/dev/form/v2',
        component: asLazyPage(
          () => import('../../components/molecules/form/page')
        ),
        exact: false,
      },
      {
        path: '/dev/flags',
        component: asLazyPage(() => import('../../pages/devFlags')),
        exact: false,
      },
    ]
  : [];
