import { createContext, FC, useContext } from 'react';

import { IServiceFactory } from './serviceFactoryInterface';
import { IBareClientRepo } from './services/bareClient/IBareClientRepo';
import { IExternalFormsRepo } from './services/externalForms/IExternalForms';
import { IFieldAliasesRepo } from './services/fieldAliases/IFieldAliases';
import { IFormRepo } from './services/form/IFormRepo';
import { IFreshdeskTokenRepo } from './services/freshdeskToken/IFreshdeskTokenRepo';
import { IGridRepo } from './services/grid/IGridRepo';
import { IIndexingDocumentsRepo } from './services/indexingDocuments/IIndexingDocumentsRepo';
import { IIndexingSourcesRepo } from './services/indexingSources/IIndexingSourcesRepo';
import { IManagementEmailRepo } from './services/managementEmail/IManagementEmailRepo';
import { IManagementLogsRepo } from './services/managementLogs/IManagementLogsRepo';
import { IPrintRenderLogRepo } from './services/printRenderLogs/IPrintRenderRepo';
import { IProcessRepo } from './services/process/IProcessRepo';
import { IProcessInstanceRepo } from './services/ProcessInstance/IProcessInstanceRepo';
import { IProcessInstanceRepoV2 } from './services/ProcessInstanceV2/IProcessInstanceRepo';
import { IScanRepo } from './services/Scan/IScanRepo';
import { IScanScenarioRepo } from './services/ScanScenario/IScanScenarioRepo';
import { ISecurityRepo } from './services/security/ISecurityRepo';
import { ISystemLogsRepo } from './services/systemLogs/ISystemLogsRepo';
import { IUserRepo } from './services/users/IUserRepo';

const defaultFactory: IServiceFactory = {
  getScanRepo(): IScanRepo {
    return {} as IScanRepo;
  },
  getPrintRenderRepo(): IPrintRenderLogRepo {
    return {} as IPrintRenderLogRepo;
  },
  getIndexingSourceRepo(): IIndexingSourcesRepo {
    return {} as IIndexingSourcesRepo;
  },
  getIndexingDocumentsRepo(): IIndexingDocumentsRepo {
    return {} as IIndexingDocumentsRepo;
  },
  getProcessInstanceRepo(): IProcessInstanceRepo {
    return {} as IProcessInstanceRepo;
  },
  getScanScenarioRepo(): IScanScenarioRepo {
    return {} as IScanScenarioRepo;
  },
  getFreshdeskTokenRepo(): IFreshdeskTokenRepo {
    return {} as IFreshdeskTokenRepo;
  },
  getSystemLogsRepo(): ISystemLogsRepo {
    return {} as ISystemLogsRepo;
  },
  getManagementLogsRepo(): IManagementLogsRepo {
    return {} as IManagementLogsRepo;
  },
  getUserRepo(): IUserRepo {
    return {} as IUserRepo;
  },
  getManagementEmailRepo(): IManagementEmailRepo {
    return {} as IManagementEmailRepo;
  },
  getProcessRepo(): IProcessRepo {
    return {} as IProcessRepo;
  },
  getBareClientRepo(): IBareClientRepo {
    return {} as IBareClientRepo;
  },
  getFieldAliasRepo(): IFieldAliasesRepo {
    return {} as IFieldAliasesRepo;
  },
  getProcessInstanceRepoV2(): IProcessInstanceRepoV2 {
    return {} as IProcessInstanceRepoV2;
  },
  getGridRepo(): IGridRepo {
    return {} as IGridRepo;
  },
  getExternalFormsRepo(): IExternalFormsRepo {
    return {} as IExternalFormsRepo;
  },
  getSecurityRepo(): ISecurityRepo {
    return {} as ISecurityRepo;
  },
  getFormRepo(): IFormRepo {
    return {} as IFormRepo;
  },
};

const DepsContext = createContext<IServiceFactory>(defaultFactory);

interface serviceProps {
  serviceFactory: IServiceFactory;
  children?: JSX.Element;
}

export function useAppServices(): IServiceFactory {
  return useContext(DepsContext);
}

export const DepsProvider: FC<serviceProps> = ({
  children,
  serviceFactory,
}) => (
  <DepsContext.Provider value={serviceFactory}>{children}</DepsContext.Provider>
);
