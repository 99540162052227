import { ApiResponseAsync, isApiError } from 'container/models';

import { ApiPaths } from 'api/constants';

import ApiUrlParams from 'constants/apiUrlParams';

import {
  CreateResult,
  FormGeneral,
  FormMutationPayload,
  GetAllFormsResult,
  GetFormByIdResult,
  UpdateByIdResult,
} from '../../../api/endpoints/processForm';
import BaseRepo from '../../BaseRepo';
import { buildBadRequestApiError } from '../../helpers/errors';
import { IFormRepo } from './IFormRepo';

export class FormRepo extends BaseRepo implements IFormRepo {
  getAllForms = async (
    releaseOrDraftProcessId: string
  ): ApiResponseAsync<GetAllFormsResult> => {
    const url = ApiPaths.processes.byId.processForms._({
      [ApiUrlParams.releaseOrDraftProcessId]: releaseOrDraftProcessId,
    });
    return this.helpers.handleGet<GetAllFormsResult>(url);
  };

  getFormDetails = (
    releaseOrDraftProcessId: string,
    formId: string
  ): ApiResponseAsync<GetFormByIdResult> => {
    const url = ApiPaths.processes.byId.processForms.byId._({
      [ApiUrlParams.releaseOrDraftProcessId]: releaseOrDraftProcessId,
      [ApiUrlParams.formId]: formId,
    });
    return this.helpers.handleGet<GetFormByIdResult>(url);
  };

  /**
   * First we must get the fields associated with a form, then we update the form using a PUT request
   *
   * TODO: don't use a PUT request
   * @param form
   * @param setAsFinal
   * @param draftProcessId
   */
  updateFormIsFinal = async (
    form: FormGeneral,
    setAsFinal: boolean,
    draftProcessId: string
  ): ApiResponseAsync<void> => {
    // if the links don't exist, or there's a mismatch between the user setting a form and the link, then we throw an error
    if (
      !form.links ||
      (setAsFinal && !form.links['setIsFinal']) ||
      (!setAsFinal && !form.links['clearIsFinal'])
    )
      return Promise.resolve(
        buildBadRequestApiError(
          new Error(
            `link ${setAsFinal ? 'set final' : 'clear final'} form is not available`
          )
        )
      );

    // get form details associated with the given form
    const selectedFormDetails = await this.getFormDetails(
      draftProcessId,
      form.id
    );

    if (isApiError(selectedFormDetails)) {
      return Promise.resolve(
        buildBadRequestApiError(new Error('form details not found'))
      );
    }

    if (!selectedFormDetails.fields)
      return Promise.resolve(
        buildBadRequestApiError(
          new Error('form without fields cannot be a final form')
        )
      );

    let link: Link | undefined;

    link = setAsFinal ? form.links['setIsFinal'] : form.links['clearIsFinal'];

    const formPayload: FormMutationPayload = {
      ...form,
      description: form.description ?? '',
      instanceNameFieldId: form.instanceNameField?.id,
      fields:
        selectedFormDetails.fields.map((field) => ({
          fieldId: field.id,
          fieldObligationType: field.obligationType,
          multiValues: field.multiValues,
        })) ?? [],
      isFinal: setAsFinal,
    };

    return this.helpers.handlePut<FormMutationPayload, void>(
      link.href,
      formPayload
    );
  };

  updateForm = async (
    formPayload: FormMutationPayload,
    draftProcessId: string,
    formId: string
  ): ApiResponseAsync<UpdateByIdResult> => {
    const url = ApiPaths.processes.byId.processForms.byId._({
      [ApiUrlParams.releaseOrDraftProcessId]: draftProcessId,
      [ApiUrlParams.formId]: formId,
    });

    return this.helpers.handlePut<FormMutationPayload, UpdateByIdResult>(
      url,
      formPayload
    );
  };

  createForm = async (
    formPayload: FormMutationPayload,
    draftProcessId: string
  ): ApiResponseAsync<CreateResult> => {
    const url = ApiPaths.processes.byId.processForms._({
      [ApiUrlParams.releaseOrDraftProcessId]: draftProcessId,
    });

    return this.helpers.handlePost<FormMutationPayload, CreateResult>(
      url,
      formPayload
    );
  };
}
